var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.sampleLink &&
    _vm.sampleLink !== "" &&
    _vm.sampleLink.includes("www")
    ? _c(
        "div",
        { staticClass: "sample-button" },
        [
          _c(
            "v-btn",
            {
              attrs: {
                text: "",
                color: _vm.color,
                href: _vm.sampleLink,
                target: "_blank"
              }
            },
            [
              _c(
                "div",
                [
                  _vm._v(" " + _vm._s(_vm._sampleTitle) + " "),
                  _c("v-icon", { staticClass: "arrow-forward" }, [
                    _vm._v(" sell ")
                  ])
                ],
                1
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }