var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "carousel" },
    [
      _c("div", { staticClass: "title" }, [
        _c("h1", [
          _vm._v(
            " " +
              _vm._s(_vm.DCSLanguageService.translate("NewProductTitle")) +
              " "
          )
        ])
      ]),
      _c("div", { staticClass: "text-media-container" }, [
        _vm.newProduct
          ? _c(
              "div",
              { staticClass: "text-container" },
              [
                _c("ShareButtonComponent", {
                  attrs: {
                    "share-link": _vm.newProduct.buttonLink,
                    color: "white"
                  }
                }),
                _c("h2", [_vm._v(" " + _vm._s(_vm.newProduct.title) + " ")]),
                _c("div", { staticClass: "btn-container" }, [
                  _vm.newProduct.applicationGuideLink
                    ? _c(
                        "div",
                        { staticClass: "app-guide-btn" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "link-button",
                              attrs: {
                                text: "",
                                small: "",
                                href: _vm.newProduct.applicationGuideLink,
                                disabled: !_vm.newProduct.applicationGuideLink,
                                target: "_blank"
                              }
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-format-list-numbered")
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.DCSLanguageService.translate(
                                          "ApplicationGuide"
                                        )
                                      ) +
                                      " "
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.newProduct.productGuideLink
                    ? _c(
                        "div",
                        { staticClass: "prod-guide-btn" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "link-button",
                              attrs: {
                                text: "",
                                small: "",
                                href: _vm.newProduct.productGuideLink,
                                disabled: !_vm.newProduct.productGuideLink,
                                target: "_blank"
                              }
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-information-outline")
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.DCSLanguageService.translate(
                                          "ProductGuide"
                                        )
                                      ) +
                                      " "
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _c("div", { staticClass: "new-product-detail-container" }, [
                  _c("p", [_vm._v(_vm._s(_vm.newProduct.description))])
                ]),
                _c(
                  "div",
                  { staticClass: "btn-container" },
                  [
                    _vm.newProduct.buttonLink
                      ? _c(
                          "div",
                          { staticClass: "explore-more-btn" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "link-button",
                                attrs: {
                                  text: "",
                                  small: "",
                                  href: _vm.newProduct.buttonLink,
                                  disabled: !_vm.newProduct.buttonLink,
                                  target: "_blank"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.DCSLanguageService.translate(
                                            "ExploreMore"
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c(
                                      "v-icon",
                                      { staticClass: "arrow-forward" },
                                      [_vm._v(" arrow_forward ")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("RequestSampleButtonComponent", {
                      staticClass: "request-sample-btn",
                      attrs: {
                        "sample-link": _vm.newProduct.sampleLink,
                        "sample-title": _vm.newProduct.sampleTitle,
                        color: "white"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm.newProduct
          ? _c(
              "div",
              { staticClass: "media-container" },
              [
                _c("MediaCarouselComponent", {
                  attrs: { media: _vm.newProduct.media }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "slide-group-container" },
        [
          _vm.newProducts.length
            ? _c("SlideGroupComponent", {
                attrs: { "carousel-items": _vm.newProducts },
                on: { "item-select": _vm.productSelected }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.hasNoNewProducts
        ? _c("div", { staticClass: "display-image" }, [
            _c("img", {
              staticClass: "display-image",
              attrs: { src: require("@/assets/no_new_products.svg") }
            }),
            _c("h2", [
              _vm._v(
                " " +
                  _vm._s(_vm.DCSLanguageService.translate("NoNewProducts")) +
                  " "
              )
            ])
          ])
        : _vm._e(),
      _vm.isLoading ? _c("CarouselPageSkeletonLoader") : _vm._e(),
      _c("CityDarkBGComponent", {
        attrs: { "modifier-class": "dark-bg--fixed" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }