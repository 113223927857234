























import { Component, Vue } from "vue-property-decorator";

const RequestSampleButtonProps = Vue.extend({
  props: {
    sampleTitle: {
      default: "Request Sample",
      type: String,
    },
    sampleLink: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: "primary"
    }
  },
});

@Component
export default class RequestSampleButtonComponent extends RequestSampleButtonProps {
  public get _sampleTitle(): string {
    return this.sampleTitle ? this.sampleTitle : "Request Sample";
  }
}
