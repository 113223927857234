




































































































































import { Vue, Component } from "vue-property-decorator";

import { take } from "rxjs/operators";

import CityDarkBGComponent from "@/components/core/backgrounds/CityDarkBG.vue";
import MediaCarouselComponent from "@/components/media/carousel/MediaCarousel.vue";
import SlideGroupComponent from "@/components/media/slide-group/SlideGroup.vue";
import RequestSampleButtonComponent from "@/components/products/buttons/RequestSampleButton.vue";
import ShareButtonComponent from "@/components/products/buttons/ShareButton.vue";
import CarouselPageSkeletonLoader from "@/components/media/carousel/CarouselPageSkeletonLoader.vue";

import { DCSLanguageService, ProductService, MetaService } from "@/services";
import { NewProduct, ProductDetail } from "@/types";
import { ComponentOptions } from "vue/types/options";
import { blobDataPath } from "@/util";

const metaInfo: ComponentOptions<Vue>['metaInfo'] = {
  title: 'Our latest innovations',
  meta: [
    {
      vmid: 'description',
      name: 'description',
      content: 'Peruse our latest product innovations in Dow Building Science.',
    },
  ],
};

export const metaInfoMixin = {
  metaInfo,
};

@Component({
  mixins: [metaInfoMixin],
  components: {
    CityDarkBGComponent,
    MediaCarouselComponent,
    SlideGroupComponent,
    RequestSampleButtonComponent,
    ShareButtonComponent,
    CarouselPageSkeletonLoader
  },
})
export default class NewProducts extends Vue {
  // kept the code written for the event service, but using the one for webinars until it is working

  public DCSLanguageService = new DCSLanguageService();
  public blobDataPath = blobDataPath;

  private productService = new ProductService();
  private metaService = new MetaService();

  newProduct: NewProduct | null | undefined = null;
  newProducts: NewProduct[] = [];

  hasNoNewProducts = false;
  isLoading = true;

  productSelected(index: number): void {
    this.newProduct = this.newProducts[index];
  }

  private created(): void {
    this.metaService.updateMetaDescription('Peruse our latest product innovations in Dow Building Science.');
    this.metaService.updateMetaTitle('Our latest innovations');

    this.productService
      .getProductListDataByNewProduct()
      .pipe(take(1))
      .subscribe((newProducts: ProductDetail[]) => {

        this.newProducts = newProducts.map((NP) => {
          return {
            id: NP.id,
            title: NP.heading, 
            description: NP.description, 
            isFeatured: false,
            media: NP.videos.length > 0 ? {
              mediaType: 'video',
              videoUrl: NP.videos[0].url, 
              image: NP.videos[0].thumbnail
            } : NP.media.length > 0 ? {
              mediaType: NP.media[0].type,
              videoUrl: blobDataPath + NP.media[0].sources[0].url,
              image: NP.media[0].poster
            } : [],
            applicationGuideLink: NP.applicationGuideLink,
            productGuideLink: NP.productGuideLink,
            buttonLink: NP.buttonLink,
            sampleTitle: NP.sampleTitle,
            sampleLink: NP.sampleLink
          } as NewProduct
        });
        this.newProduct = this.newProducts[0] ?? null;  
        if(this.newProducts.length == 0)
          this.hasNoNewProducts=true;
        this.isLoading = false;
      });   
  }
}
